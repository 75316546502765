import React from "react"
import Layout from "../components/layout"
import Formrelationretro from "../components/formrelationretro"
import SEO from "../components/seo"
import { graphql, Link } from 'gatsby'
import MathJax from 'react-mathjax2'
import Img from "gatsby-image"
import "../assets/css/closedform.css";







const Retrofitted = ({ data }) => {
  
  
  
  
    let entity = data.strapiCapacityRetrofittedClosedForms;
    let title = 'Closed form relationships: '+ entity.Name;
  let metatitle = title;
  let description = '';
  let metadescription = description;
  
    let style = {
      overflowX: 'auto' 
    };

    // if (typeof window !== 'undefined') {
    // $(document).ready(function () {
    //   $('.anchornav button').click(function(){
    //     var target = $(this).attr('data-target');
    //     var off = 20;
    //     var offset = $('#'+target).offset().top - off;
    //     $('html,body').animate({scrollTop: offset},'slow');
    //   });
    // });
    // }
    return(
      
        <Layout bodyclass="pier" title={title}>
          <SEO
            title={metatitle}
            description={metadescription}
          />
          <nav className="anchornav">
            <ul>
          {data.allStrapiCapacityRetrofittedClosedForms.edges.map(edge=>{
            let entity2 = edge.node;
            let actclass = '';
            if(entity2.id === entity.id){
              actclass = 'actclass';
            }
            return(
              <li key={entity2.id}>
                <Link className={`more ${actclass}`} to={`/bridge-specific/capacity/retrofitted/ls-closed-form/${entity2.slug}`}>{entity2.Name}</Link>
              </li>
            )
            })}
          
          </ul>
          </nav>
          
          <div className="content">
                <div id={entity.id} className="closedform" key={entity.id}>
                  <Img className="mainimage" fluid={entity.Image.childImageSharp.fluid} />
                  <div style={style}>
                  <table>
                    <thead>
                      <tr>
                        <th>Parameters</th>
                        <th colSpan="6">Values</th>
                      </tr>
                    </thead>
                    <tbody>
                    {entity.ParametersTable.map(row =>{
                      let valuesarray = row.ParameterValues.split(",");
                      
                      return(
                        <tr key={row.id}>
                          <td dangerouslySetInnerHTML={{__html: row.ParameterName}} />
                          {valuesarray.map((value,index)=>{
                            return(
                              <td key={index}>{value}</td>
                            )
                          })}
                        </tr>
                      )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colSpan="7">Combinations Number: {entity.CombinationsCount}</td>
                    </tr>
                    </tfoot>
                  </table>
                  </div>
                  {entity.M_phi.map(m_phi => {
                    return(
                      <div className="analysis_section" key={m_phi.id}>
                        <h3>{m_phi.Title}</h3>
                        <div className="relations">
                          <MathJax.Context input='ascii'>
                            <div>
                                <MathJax.Node>{m_phi.M_Relationship_Tex}</MathJax.Node>
                            </div>
                          </MathJax.Context>
                          <MathJax.Context input='ascii'>
                            <div>
                            <MathJax.Node>{m_phi.Phi_Relationship_Tex}</MathJax.Node>
                            </div>
                          </MathJax.Context>
                        </div>
                        
                        {m_phi.M_phi_Table.map(table =>{
                          
                          var relationship = '';
                          var type= table.Type;
                          if(type === "Phi"){
                            relationship = m_phi.Phi_Relationship;
                          }else{
                            relationship = m_phi.M_Relationship;
                          }
                          var length = 0;
                          var tablehead = '<th></th>';
                          var tablebody = '<td>'+table.Parameter+'</td>';
                          for( var key in table ) {
                              if( table.hasOwnProperty(key) && key !== 'Parameter' &&key !== 'Type' && key !== 'id' ) {
                                if (table[key] === null || table[key] === undefined) {
                                  
                                  //do nothing
                                }else{
                                  let newkey = key;
                                  
                                  if(key === 'b0' || key === 'b1' || key === 'b2' || key === 'b3' || key === 'b4' || key === 'b5' || key === 'b6' || key === 'b7'){
                                    newkey = 'β<sub>'+newkey.slice(newkey.length - 1)+'</sub>';
                                  }
                                  tablehead += '<th>'+newkey+'</th>';
                                  tablebody += '<td>'+table[key]+'</td>';
                                  ++length;
                                }
                              }
                          }
                          
                          
                          return(
                            <>
                            <div style={style}>
                              <h4><span dangerouslySetInnerHTML={{__html: table.Parameter}} /> Calculation</h4>
                              <table>
                                <thead>
                                <tr dangerouslySetInnerHTML={{__html: tablehead}} />
                                </thead>
                                <tbody>
                                <tr dangerouslySetInnerHTML={{__html: tablebody}} />
                                </tbody>
                              </table>
                            </div>
                            <p className="form-info" >Please fill in the following parameters and press "Calculate".</p>
                            <Formrelationretro 
                              id={entity.id}
                              type={type}
                              length={length}
                              b0={table.b0}
                              b1={table.b1}
                              b2={table.b2}
                              b3={table.b3}
                              b4={table.b4} 
                              b5={table.b5}
                              b6={table.b6}
                              param={table.Parameter}
                              relationship={relationship}

                            />
                            
                          </>
                          )
                        })}
                      </div>
                    )
                  })}
                  {entity.d_H.map(d_H => {
                    return(
                      <div className="analysis_section" key={d_H.id}>
                        <h3>{d_H.Title}</h3>
                        <div className="relations">
                          <MathJax.Context input='ascii'>
                            <div>
                                <MathJax.Node>{d_H.d_Relationship_Tex}</MathJax.Node>
                            </div>
                          </MathJax.Context>
                        </div>
                        {d_H.d_H_Table.map(table =>{
                          
                          
                          var length = 0;
                          var tablehead = '<th></th>';
                          var tablebody = '<td>'+table.Parameter+'</td>';
                          for( var key in table ) {
                              if( table.hasOwnProperty(key) && key !== 'Parameter' && key !== 'id' ) {
                                if (table[key] === null || table[key] === undefined) {
                                  
                                  //do nothing
                                }else{
                                  let newkey = key;
                                  
                                  if(key === 'b0' || key === 'b1' || key === 'b2' || key === 'b3' || key === 'b4' || key === 'b5' || key === 'b6' || key === 'b7'){
                                    newkey = 'β<sub>'+newkey.slice(newkey.length - 1)+'</sub>';
                                  }
                                  tablehead += '<th>'+newkey+'</th>';
                                  tablebody += '<td>'+table[key]+'</td>';
                                  ++length;
                                }
                              }
                          }
                          
                          
                          return(
                            <>
                            <div style={style}>
                            <h4><span dangerouslySetInnerHTML={{__html: table.Parameter}} /> Calculation</h4>
                            <table>
                              <thead>
                              <tr dangerouslySetInnerHTML={{__html: tablehead}} />
                              </thead>
                              <tbody>
                              <tr dangerouslySetInnerHTML={{__html: tablebody}} />
                              </tbody>
                            </table>
                            </div>
                            <p className="form-info" >Please fill in the following parameters and press "Calculate".</p>
                            <Formrelationretro 
                              id={entity.id}
                              length={length}
                              b0={table.b0}
                              b1={table.b1}
                              b2={table.b2}
                              b3={table.b3}
                              b4={table.b4} 
                              b5={table.b5}
                              b6={table.b6}
                              b7={table.b7}
                              param={table.Parameter}
                              relationship={d_H.d_H_Relationship}

                            />
                            
                          </>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              
          </div>
        </Layout>
      )
    
}
export default Retrofitted

export const query = graphql`
  query RetrofittedTemplate($id: String!) {
    strapiCapacityRetrofittedClosedForms(id: {eq: $id}){
    id
    Name
    M_phi {
      id
      Title
      Phi_Relationship
      M_Relationship
      M_Relationship_Tex
      Phi_Relationship_Tex
      M_phi_Table {
        id
        Parameter
        b0
        b1
        b2
        b3
        b4
        b5
        Type
      }
    }
    d_H {
      id
      Title
      d_H_Relationship
      d_Relationship_Tex
      d_H_Table {
        id
        Parameter
        b0
        b1
        b2
        b3
        b4
        b5
      }
    }
    CombinationsCount
    ParametersTable {
      id
      ParameterName
      ParameterValues
    }
    Image {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
      
  }
  allStrapiCapacityRetrofittedClosedForms{
    edges {
      node {
        id
        Name
        slug
      }
    }
  }
}
`
 